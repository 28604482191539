import TextBlock from "../../Components/TextBlock/TextBlock"

const Schedule = () => {

    const Content = () => {

        return (
            <TextBlock>
                <>
                    <h2>Schema</h2>

                    <p>Vi kommer uppdatera den här sidan med mer information allt eftersom. 👷‍♂️👷‍♀️</p>

                    <h3>Fredag</h3>
                    <p>För er som önskar anlända redan på fredagen så önskar vi er varmt välkomna! Vi har en mysig quiz-kväll med mat och trevlig stämning i Brygghuset.

                        Vi slår upp dörrarna 18.30 men det är drop-in under kvällen. Quiz och mat startar vid runt klockan 20. 
                        Ifall ni sover över på slottet hittar ni nycklar och rumsinformation i Brygghuset.
                    </p>
                    <p>
                        Vi önskar en barnfri helg, men vi vet också hur svårt det kan vara att få barnvakt två kvällar i rad. 
                        Eftersom fredagen är lite lugnare så får ni ta med er barn på fredagen ifall det underlättar för er, meddela i anmälan.
                        Dock får ni räkna med att dela säng eller ta med sig en egen resesäng till ert barn för er som sover på slottet.
                    </p>

                    <h3>Lördag</h3>
                    <p>För er som sovit över på slottet börjar dagen med frukost i Östra Flygeln. Sen är det fri lek fram till vigseln.
                        Det finns lite olika aktiviteter på slottet så som kägelbana, bastu, kubb, kanoter och tennisbana. Ni ansvarar själva för att hitta något kul och att plocka undan efter er.
                        Det kan också vara trevligt att göra en liten utflykt i närområdet, kanske med bil til Sparreholm eller Malmköping. För de som önskar, så serveras en enklare lunch på slottet vid 12.</p>

                    <p>Ifall man ankommer på lördagen och ska sova på slottet så hittar ni nycklar och rumsinformation i Östra Flygeln.</p>

                    <p>Vigseln sker utomhus vid bryggan bakom slottet klockan 14.00.
                        Vid regn eller annat otrevligt väder sker vigsel inne i slottet.</p>

                    <p>Middagen börjar i Gula Paviljongen klockan 17.30.</p>

                    <p>Efter middagen är det fest tills vi inte orkar mer!</p>
                    <p>Lördagen önskar vi ha barnfri, med undantag för ammande barn så klart.</p>

                    <h3>Söndag</h3>
                    <p>För er som sovit över på slottet börjar dagen med frukost i Östra Flygeln.
                        Sedan säger vi tack för att ni kom och firade vår stora dag med oss! Senast klockan 12.00 behöver ni lämna slottet. </p>

                </>
            </TextBlock>

        )
    }

    return (
        <div className="Page">
            <Content />
        </div>
    )
}

export default Schedule;