import TextBlock from "../../Components/TextBlock/TextBlock"

const Staying = () => {

    const Content = () => {

        return (
            <TextBlock>
                <>
                    <h2>Boende</h2>
                    <p>Rockelstad Slott har många mysiga rum i olika hus och flyglar där gäster kan sova över. Det finns plats till nästan alla gäster på slottet.
                        Ni måste inte bo på slottet men ni får gärna göra det om ni vill.
                        Ifall fler önskar boende än vad som finns plats för så kommer vi att prioritera långväga gäster.</p>

                    <p>Notera att ni sannolikt får dela rum och badrum, självklart placerar vi er med någon eller några som ni känner väl.</p>

                    <p>Lakan och handdukar finns på plats.</p>

                    <p>Ifall ni vill bo på slottet så kostar det 500 kronor per person och natt, betalningsinformation kommer. Tex, ifall ni är två stycken som vill sova över från fredag till söndag så kostar det totalt 2000 kronor.</p>

                    <p>Ifall ni inte vill bo på slottet kan vi rekommendera:</p>
                    <p><a href="https://www.sparreholmbnb.se/">Sparreholm Bed and Breakfast</a>, cirka 6 kilometer bort.</p>
                    <p><a href="https://www.hotelmalmkoping.se/sv/">Hotell Malmköping</a>, cirka 16 kilometer bort.</p>
                    <p><a href="https://solbackakrog.se/">Solbacka krog</a>, cirka 21 kilometer bort.</p>
                    <p><a href="https://www.plevnagarden.se/">Plevnagården</a>, cirka 17 kilometer bort.</p>

                </>
            </TextBlock>

        )
    }

    return (
        <div className="Page">
            <Content />
        </div>
    )
}

export default Staying;