
const RSVP = () => {

    const Content = () => {

        return (
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeULsjGZrFnJTq5k_0xl6ModPjH8FT6OihVNvCGNgQyyHxlvg/viewform?embedded=true" width="640px" height="1001px" frameBorder="0" marginHeight={0} marginWidth={0}>Läser in …</iframe>
        )
    }

    return (
        <div className="Page">
            <Content />
        </div>
    )
}

export default RSVP;