import "./Landing.css"
import img from "./landing2.jpg"
import TextBlock from "../../Components/TextBlock/TextBlock";


const Landing = () => {

    const Content = () => {

        return (
            <TextBlock>
                <>
                    <h2>Välkomna på bröllop 23e augusti 2025!</h2>
                    <p>Vi gifter oss på Rockelstad Slott och vi ser fram emot att dela dagen med er.</p>
                    <div className="allignedParagraph">

                        <p>Vart? Rockelstad Slott, Sverige</p>
                        <p>När? Vigsel och fest är lördagen den 23e augusti, men ni är är varmt välkomna redan från fredag kväll.</p>
                        <p>Boende? Slottet har plats för nästan alla gäster, se mer under fliken “Boende”</p>
                        <p>Klädkod? Kavaj</p>
                        <p>Anmälan? Senast 1 mars via fliken “Anmälan”</p>
                    </div>
                </>
            </TextBlock >
        )
    }

    return (
        <div className="Page">
            <div className="imgBox">
                <img src={img} className="image" height="auto" width="70%"></img>
            </div>
            <Content />
        </div>
    )
}

export default Landing;