
import "./History.css"
import img from "./images/landing2.jpg"
import img2014 from "./images/2014-2.jpg"
import img20151 from "./images/2015-1.jpg"
import img20152 from "./images/2015-2.jpg"
import img2016 from "./images/2016.jpg"
import img20171 from "./images/2017.jpg"
import img20172 from "./images/2017-2.jpg"
import img2018 from "./images/2018.jpg"
import img2019 from "./images/2019.jpg"
import img2020 from "./images/2020.jpg"
import img2021 from "./images/2021.jpg"
import img20221 from "./images/2022.jpg"
import img20222 from "./images/2022-2.jpg"
import img20231 from "./images/2023.jpg"
import img20232 from "./images/2023-2.jpg"
import img2024 from "./images/2024.jpg"
import img2025 from "./images/DSC_0215.jpg"


const History = () => {

    const Content = () => {

        return (
            <div className="textBlock">
                    <h2>Vår historia</h2>
                    <div className="eventBoxContainer">
                
                    <div className="eventBox">
                        <img src={img2014} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                            <h3>2014</h3>
                            <p>Vi började dejta och blev ett par i september. </p>
                        </div>
                    </div>

                    <div className="eventBox">
                        <img src={img20151} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2015</h3>
                        <p>Vår första utlandsresa gick till Köpenhamn och Berlin. Där såg vi baletten Törnrosa och en av oss åt currywurst. Vi frös också otroligt mycket för det var inte alls så vårigt som vi trodde.</p>
                        </div>
                        </div>
                    </div>

                    <div className="eventBox">
                        <img src={img20152} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2015</h3>
                        <p>Ett händelserikt år studiesocialt där Jonatan arrangerade DöMD med sitt D-Group och Sara arrangerade nolle-p med sitt STABEN. På toppen av det så flyttade vi även ihop!</p>
                        </div>
                    </div>

                    <div className="eventBox">
                        <img src={img2016} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2016</h3>
                        <p>Vi avslutade båda vår kandidatexamen samtidigt som vi sprang på en del fester.</p>
                        </div>
                    </div>

                    <div className="eventBox">
                        <img src={img20171} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2017</h3>
                        <p>Vuxenlivet började kalla på oss och vi sommarjobbade i Oslo och Linköping medan vi mentalt förberedde oss på sista året som studenter.</p>
                        </div>
                    </div>

                    <div className="eventBox">
                        <img src={img20172} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2017</h3>
                        <p>Vi flydde kalla Norden för 10 veckor i Asien under hösten och vintern. Vi hann besöka Sydkorea, Hong Kong, Vietnam, Kambodja, Filippinerna och Singapore. Under den tiden hann vi uppleva spännande mat, vackra vandringar, coola tempelruiner, härliga stränder, en tyfon, några monsunregn och en nyårsfest på en strand.</p>
                        </div>
                    </div>

                    <div className="eventBox">
                        <img src={img2018} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2018</h3>
                        <p>Vi tog examen, sa hej då till Linköping och flyttlasset gick till Oslo. Där började nu livet som seriösa ingenjörer och vi gick både en och två och tre vandringar i vackra Oslomarka.</p>
                        </div>
                    </div>

                    <div className="eventBox">
                        <img src={img2019} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2019</h3>
                        <p>Vår första tågluff! Vi tog tåget genom Tyskland, Nederländerna, Belgien och Luxemburg, en resa som gav mersmak för tågåkande.</p>
                        </div>
                    </div>

                    <div className="eventBox">
                        <img src={img2020} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2020</h3>
                        <p>Pandemin slog till och gränsen hem stängdes. Vi passade på att åka den populära turen “Norway in a nutshell”, där man åker tåg och färja Oslo-Bergen-Flåm-Oslo. Sara kunde inte gå i uppförsbackar efter sin blodpropp men vi hade trevligt ändå.</p>
                        </div>
                    </div>

                    <div className="eventBox">
                        <img src={img2021} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2021</h3>
                        <p>Vi köpte och flyttade in vår första lägenhet! Vi fick lära oss hur man spacklar och målar väggar.</p>
                        </div>
                    </div>

                    <div className="eventBox">
                        <img src={img20221} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2022</h3>
                        <p>Ett intensivt år där vi hann med två tågluffar till 7 olika länder, och 4 stycken bröllop.</p>
                        </div>
                    </div>

                    <div className="eventBox">
                        <img src={img20222} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2022</h3>
                        <p>Vi förlovade oss en stjärnklar kväll i november ute i skogen med utsikt över ett Oslo upplyst av fullmånen.</p>
                        </div>
                    </div>

                    <div className="eventBox">
                        <img src={img20231} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2023</h3>
                        <p>Välkommen Hugo!</p>
                        </div>
                    </div>

                    <div className="eventBox">
                        <img src={img20232} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2023</h3>
                        <p>Sara blev nominerad till ett pris, så vi packade ihop vagnen och en miljon bebisgrejer och drog till Island för att gå på gala. Förutom galan hann vi också uppleva en geyser, flera vattenfall, ungefär 2000 jordbävningar och en magsjuka.</p>
                        </div>
                    </div>

                    <div className="eventBox">
                        <img src={img2024} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2024</h3>
                        <p>Hugo blev resvan, vi åkte tåg till Sverige ett gäng gånger och drog till London.</p>
                        </div>
                    </div>

                    <div className="eventBox last">
                        <img src={img2025} className="imageHistory" height="auto" width="20%"></img>
                        <div className="textBox">
                        <h3>2025</h3>
                        <p>Vi gifter oss!</p>
                        </div>
                    </div>
                <div/>
            </div>
        )
    }

    return (
        <div className="Page">
            <Content />
        </div>
    )
}

export default History;