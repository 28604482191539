import { useState } from "react";
import "./App.css"
import Landing from "./Pages/Landing/Landing";
import Staying from "./Pages/Staying/Staying";
import Schedule from "./Pages/Schedule/Schedule";
import History from "./Pages/History/History";
import RSVP from "./Pages/RSVP/RSVP";

type Pages = 'Välkommen' | 'Schema' | 'Boende' | 'Vår historia' | "Anmälan"

const navItems = ['Välkommen', 'Schema', 'Boende', 'Vår historia', "Anmälan"] as Pages[];

const App = () => {
  const [tab, setTab] = useState<Pages>("Välkommen")

  const date1 = new Date();
  const date2 = new Date(2025, 7, 23);
  const diff = Math.abs(date1.getTime() - date2.getTime());
  const diffDays = Math.ceil(diff / (1000 * 3600 * 24));


  const Content = () => {
    switch (tab) {
      case "Välkommen":
        return (
          <Landing />
        )

      case 'Boende':
        return (
          <Staying />
        )
      case 'Schema':
        return (
          <Schedule />
        )
      case 'Vår historia':
        return (
          <History />
        )
      case "Anmälan":
        return (
          <RSVP />
        )
      default:
        return (
          <p>About</p>
        )
    }
  }

  return (
    <div className="App">
      <h1>Sara & Jonatan</h1>
      <div className="headerGroup">
        <h3>23 Augusti 2025</h3>
        <h3>💍</h3>
        <h3>Rockelstad slott</h3>
      </div>
      <h3>{diffDays} dagar kvar!</h3>
      <div className="container">

        <div className="navbar">
          {navItems.map(mappedTab => {
            return (<button className={"NavButton " + (mappedTab == tab ? "Selected" : "NotSelected")} onClick={() => { setTab(mappedTab) }}>{mappedTab}</button>)
          })}
        </div>

        <Content />
      </div>
    </div>
  )
}

export default App;